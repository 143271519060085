require([
	'jquery',
], function($) {

		$(document).ready(function() {

			$('.leistungen__item > a').on('click', function() {
				$(this).next('[hidden]').slideToggle();
			})

			if(location.hash) {
				console.log($('[href="'+location.hash+'"]'))
				$('[href="'+location.hash+'"]').trigger('click');
			}

		});

});

define("app", function(){});

